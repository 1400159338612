import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Section, { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import Button from "@ui/button"

const CTASection = ({
  sectionStyle,
  heading,
  text,
  ButtonOne,
  ButtonTwo,
  ...props
}) => {


  return (
    <Section {...sectionStyle} id="#contact">
      <Container>
        <Row className="align-items-center text-lg-left text-center">
          <Col xl={8} lg={7}>
            <Heading {...heading}>
              We are looking for interns who are interested in related research
              topics. <span>Interested in joining?</span>
            </Heading>
          </Col>
          <Col xl={4} lg={5} className="text-center">
            <Button
              to="/contact-us"
              {...ButtonOne}
              icon="far fa-comment-alt-dots"
              iconposition="left"
              icondistance="8px"
              iconsize="16px"
            >
              Contact Us
            </Button>
            <Button
              to="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__sAOKrxUQzNKWjNSWUtYQjFENllRVDdLRzQ1M1lFVS4u"
              {...ButtonTwo}
              target="_blank"
              icon="far fa-info-circle"
              iconposition="left"
              icondistance="8px"
              iconsize="16px"
            >
              Apply Now
            </Button>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

CTASection.propTypes = {
  heading: PropTypes.object,
}

CTASection.defaultProps = {
  sectionStyle: {
    bgColor: "#f6f2ed",
    bgposition: "top 35% center ",
    bgsize: "auto",
    pt: "80px",
    pb: "80px",
    responsive: {
      medium: {
        pt: "60px",
        pb: "60px",
      },
      small: {
        pt: "40px",
        pb: "40px",
      },
    },
  },
  heading: {
    as: "h3",
    child: {
      color: "secondary",
    },
    responsive: {
      medium: {
        mb: "10px",
      },
    },
  },
  text: {
    as: "span",
    color: "secondary",
  },
  ButtonOne: {
    skin: "primary",
    m: "7px",
  },
  ButtonTwo: {
    skin: "primary",
    m: "7px",
  },
}

export default CTASection
