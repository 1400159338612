import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from '@layout/header/header-one'
import Footer from '@layout/footer/footer-one'
import Hero from '@containers/landing/hero'
import AboutArea from '@containers/about-us/about-area'
import SolutionsArea from '@containers/about-us/solutions-area'
import FunFactArea from '@containers/global/funfact-area/section-one'
// import TestimonialArea from '@containers/global/testimonial-area/section-one'
import NewsArea from '@containers/news-area'
import CTAArea from "@containers/global/cta-area/section-two";

const IndexPage = ({ location }) => (
	<Layout location={location}>
		<Seo />
		<Header transparent />
		<main className="site-wrapper-reveal">
			<Hero />
			<NewsArea />
			<AboutArea />
			<SolutionsArea />
			<FunFactArea />
			{/* <TestimonialArea />	 */}
			<CTAArea />
		</main>
		<Footer />
	</Layout>
)

export default IndexPage
