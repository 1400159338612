import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import SectionTitle from "@ui/section-title"
import Blog from "@components/blog/layout-one"
import RecentNewsList from "../news/recent-news-list"
import {
  BlogWrapper,
  LeftBox,
  RecentBlogWrap,
  FeaturedBlogWrap,
} from "./news-area.style"

const NewsArea = props => {
  const newsData = useStaticQuery(graphql`
    query NewsData {
      sectionData: indexInfotechnoJson(id: { eq: "infotechno-blog-content" }) {
        title
        subtitle
      }
      featuredBlog: allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___date }
        filter: {
          frontmatter: { is_featured: { eq: true }, type: { eq: "news" } }
        }
        limit: 2
      ) {
        edges {
          node {
            fields {
              slug
              dateSlug
            }
            frontmatter {
              type
              title
              date(formatString: "LL")
              format
              quote_text
              quote_author
              video_link
              featured_image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 550
                    height: 340
                    placeholder: TRACED_SVG
                    formats: WEBP
                    quality: 100
                  )
                }
              }
            }
            excerpt(format: PLAIN)
          }
        }
      }
    }
  `)
  const blogSecData = newsData.sectionData
  const featuredBlogs = newsData.featuredBlog.edges

  return (
    <BlogWrapper id="news">
      <Container>
        <Row>
          <Col lg={4}>
            <LeftBox>
              <SectionTitle
                align="left"
                title={blogSecData.title}
                subtitle={blogSecData.subtitle}
              />
              <RecentBlogWrap>
                <RecentNewsList />
              </RecentBlogWrap>
            </LeftBox>
          </Col>
          <Col lg={8}>
            <Row>
              <Col md={6} key={featuredBlogs[0].node.fields.slug}>
                <FeaturedBlogWrap>
                  <Blog content={featuredBlogs[0].node} counts={0} />
                </FeaturedBlogWrap>
              </Col>
              <Col md={6} key={featuredBlogs[1].node.fields.slug}>
                <FeaturedBlogWrap>
                  <Blog content={featuredBlogs[1].node} counts={1} />
                </FeaturedBlogWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BlogWrapper>
  )
}

export default NewsArea
