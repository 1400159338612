import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { List, ListItem, ListLink } from './recent-news-list.style'

const RecentNewsList = ({ listStyle }) => {
	const newsData = useStaticQuery(graphql`
        query RecentNewsQueryData{
            allMarkdownRemark(
              filter: {frontmatter: {is_featured: {eq: false}, type: {eq: "news"}}}, limit: 6, sort: {fields: frontmatter___date, order: DESC}) {
                edges {
                  node {
                    fields{
                        slug
                    }
                    frontmatter {
                      title
                    }
                  }
                }
            }
        }
    `)
	const latestBlogs = newsData.allMarkdownRemark.edges;
	return (
		<List>
			{latestBlogs && latestBlogs.map((latestBlog, i) => (
				<ListItem key={`${latestBlog.node.fields.slug}-${i}`}>
					<ListLink path={`/news/${latestBlog.node.fields.slug}`}>
						<i className="icon icon-1 far fa-long-arrow-right" style={{color: '#FF8C00'}}></i>
						<i className="icon icon-2 far fa-long-arrow-right" style={{color: '#FF8C00'}}></i>
						<span>{latestBlog.node.frontmatter.title}</span>
					</ListLink>
				</ListItem>
			))}
		</List>
	)
}

export default RecentNewsList;